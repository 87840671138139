import React from 'react';
import Download from '@components/common/Download';
import FooterLinks from '@components/common/FooterLinks';


const Footer: React.FC  = () => {
  return (
    <footer>
      <Download />
      <FooterLinks />
    </footer>
  );
};

export default Footer;
