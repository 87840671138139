import React, { useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
// @ts-ignore
import { NavProps } from '@lib/types';
import { StyledMenu, ListMenu } from './index.styled';
//@ts-ignore
import menuIcon from '@assets/icons/menu.svg';
//@ts-ignore
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
//@ts-ignore
import { ReactComponent as CommerceIcon } from '@assets/icons/comercio.svg';

const Menu: React.FC<NavProps> = ({ activeSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => setIsOpen(!isOpen);

  return (
    <StyledMenu isOpen={isOpen} activeSection={activeSection}>
      <ListMenu isOpen={isOpen} activeSection={activeSection}>
        <ul>
          <AnchorLink to="/#comercios">
            <li className="active-commerces" onClick={() => setIsOpen(false)}>
              Para comercios
            </li>
          </AnchorLink>
          <AnchorLink to="/#perfiles">
            <li className="active-profiles" onClick={() => setIsOpen(false)}>
              Perfiles
            </li>
          </AnchorLink>
          <AnchorLink to="/comercios/registro">
            <li onClick={() => setIsOpen(false)}>
              Contacto
            </li>
          </AnchorLink>
          {/* <AnchorLink to="/#testimonios">
            <li
              className="active-testimonials"
              onClick={() => setIsOpen(false)}
            >
              Testimonios
            </li>
          </AnchorLink> */}
          <AnchorLink to="https://comercios.bubajobs.com.ar">
            <li id="my-commerce-item">Mi comercio</li>
          </AnchorLink>
        </ul>
        <span id="close" onClick={openMenu}>
          <CloseIcon fill="white" />
        </span>
      </ListMenu>
      <AnchorLink to="https://comercios.bubajobs.com.ar">
        <span id="my-commerce">
          <CommerceIcon fill="#F24545" />
          &nbsp;Mi comercio
        </span>
      </AnchorLink>
      <span id="open" onClick={openMenu}>
        <img src={menuIcon} alt="Menu" />
      </span>
    </StyledMenu>
  );
};

export default Menu;
