import React from 'react';
import GlobalStyles from '@styles/globalStyles';

const Layout: React.FC = ({ children }) => {
  return(
    <>
      <GlobalStyles />
      {children}
    </>
  )
}

export default Layout