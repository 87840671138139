import styled from 'styled-components';
// @ts-ignore
import { MenuStyle } from '@lib/types';
import { device } from '@theme/size';
import { color } from '@theme/color';
import { px2rem } from '@styles/styleUtils';
//@ts-ignore
import bgSpiral from '@assets/images/spiral-menu.svg';
//@ts-ignore
import bgArrow from '@assets/images/arrow-menu.svg';


export const StyledMenu = styled.div<MenuStyle>`
  display: flex;

  #open {
    display: flex;
    margin-left: 1rem;
  }

  #commerce-wrapper {
    display: flex;
    flex-direction: row;
  }

  #my-commerce {
    display: none;
    width: max-content;
    letter-spacing: ${px2rem(-2)};
    margin-left: ${px2rem(13.5)};
    font-family: 'lexend Mega', sans-serif;
    font-size: ${px2rem(16)};
    color: ${color.red};
  }

  @media ${device.tablet} {
    width: 100%;

    #open {
      display: none;
    }

    #my-commerce {
      display: flex;
      align-items: center;
    }
  }
`;

export const ListMenu = styled.div<MenuStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: ${(props) => (props.isOpen ? '100vh' : '0')};
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  left: 0;
  top: 0;
  background: bottom right / 99% no-repeat url(${bgSpiral}),
    bottom left / ${px2rem(130)} no-repeat url(${bgArrow}), ${color.red};

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      padding: ${px2rem(32)} 0;
      color: ${(props) => (props.isOpen ? color.white : color.red)};
      font-family: 'lexend Mega', sans-serif;
      font-size: ${px2rem(24)};
      letter-spacing: ${px2rem(-1.5)};
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }

  #close {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.8rem 1.5rem 0 0;
  }

  #my-commerce-item {
    display: flex;
  }

  @media ${device.mobileM} {
    background: bottom right / 99% no-repeat url(${bgSpiral}),
      bottom left / ${px2rem(150)} no-repeat url(${bgArrow}), ${color.red};
  }
  
  @media ${device.mobileL} {
    background: bottom right / 99% no-repeat url(${bgSpiral}),
      bottom left / ${px2rem(170)} no-repeat url(${bgArrow}), ${color.red};
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    width: 100%;
    height: auto;
    position: relative;

    ul {
      display: flex;
      flex-direction: row;

      li {
        padding: 0;
        font-size: ${px2rem(16)};
        margin: 0 ${px2rem(24)};
        color: ${color.black};
      }

      .active-commerces{
        color: ${(props) => props.activeSection === "comercios" ? color.red : color.black};
        text-decoration: ${(props) => props.activeSection === "comercios" ? "underline" : "none"};
      }

      .active-profiles{
        color: ${(props) => props.activeSection === "perfiles" ? color.red : color.black};
        text-decoration: ${(props) => props.activeSection === "perfiles" ? "underline" : "none"};
      }

      .active-testimonials{
        color: ${(props) => props.activeSection === "testimonios" ? color.red : color.black};
        text-decoration: ${(props) => props.activeSection === "testimonios" ? "underline" : "none"};
      }
    }

    #close {
      display: none;
    }

    #my-commerce-item {
      display: none;
    }
  }
`;
