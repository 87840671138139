import styled from 'styled-components';
// @ts-ignore
import { BtnPropStyle } from '@lib/types';
import { device } from '@theme/size';
import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';


export const StyledButton = styled.div<BtnPropStyle>`
  a, p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props) => px2rem(props.wBtn)};
    height: 48px;
    border-radius: 12px;
    background-color: ${(props) => props.bgColor};
    color: ${color.white};
    font-family: 'Lexend Mega', sans-serif;
    font-size: 16px;
    letter-spacing: ${px2rem(-1.5)};
    cursor: pointer;

    span {
      margin-left: ${px2rem(5)};
    }

    @media ${device.tablet} {
      width: ${(props) => px2rem(props.wBtn + 24)};
    }
  }
`;
