import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
// @ts-ignore
import { BtnProp } from '@lib/types';
import { StyledButton } from './index.styled';

const Button: React.FC<BtnProp> = ({
  isIntern,
  link,
  icon,
  text,
  bgColor,
  wBtn,
  onClick,
}) => {
  if (isIntern) {
    return (
      <StyledButton bgColor={bgColor} wBtn={wBtn} onClick={onClick}>
        <AnchorLink to={link}>
          <p>
            {icon}
            <span>{text}</span>
          </p>
        </AnchorLink>
      </StyledButton>
    );
  }

  return (
    <StyledButton bgColor={bgColor} wBtn={wBtn} onClick={onClick}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {icon}
        <span>{text}</span>
      </a>
    </StyledButton>
  );
};

export default Button;
