import { device } from '@theme/size';
import { createGlobalStyle } from 'styled-components';
import { px2rem } from './styleUtils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }
  
  body{
    -webkit-font-smoothing: antialiased;
    padding-top: ${px2rem(52)};
    
    @media ${device.tablet} {
      padding-top: ${px2rem(105)};
    }
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
`;
