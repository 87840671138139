import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';
import { device } from '@theme/size';
import styled from 'styled-components';

export const StyledDownload = styled.div`
  width: 100%;
  background-color: ${color.black};
  padding: ${px2rem(32)} ${px2rem(10)} 0 ${px2rem(10)};
  display: flex;
  justify-content: center;

  .download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${px2rem(1024)};
    width: 100%;

    .download-app {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-footer {
        max-width: ${px2rem(327)};
        text-align: center;
        font-family: "Messapia", sans-serif;
        font-size: ${px2rem(24)};
        color: ${color.white};
        margin: 0 ${px2rem(5)} ${px2rem(24)} 0;
      }
    
      .download-options {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * {
          margin: 0 ${px2rem(10)} ${px2rem(18)} ${px2rem(10)};
        }
        
        #badge-google {
          width: ${px2rem(203)};
          height: ${px2rem(60)};
          margin: 0 ${px2rem(10)} ${px2rem(18)} ${px2rem(10)};
        }
      
        #badge-app {
          width: ${px2rem(181)};
          height: ${px2rem(60)};
          margin: 0 ${px2rem(10)} ${px2rem(18)} ${px2rem(10)};
        }
      }
    }
  }

  @media ${device.mobileM} {
    padding: ${px2rem(32)} ${px2rem(16)} 0 ${px2rem(16)};
  }

  @media ${device.tablet} {
    padding: ${px2rem(32)} ${px2rem(20)} 0 ${px2rem(20)};

    .download-wrapper {
      flex-direction: row;
      justify-content: space-between;

      .download-app {
        align-items: flex-start;

        .title-footer {
          text-align: start;
          max-width: ${px2rem(400)};
          margin: 0 0 ${px2rem(24)} 0;
        }
  
        .download-options {
          align-items: flex-start;

          & > * {
            margin: 0 ${px2rem(10)} ${px2rem(18)} 0;
            padding: 0;
          }
  
          #badge-google {
            margin: 0 ${px2rem(10)} 0 0;
            width: ${px2rem(170)};
            height: ${px2rem(50)};
          }
  
          #badge-app {
            margin: 0 ${px2rem(10)} 0 0;
            width: ${px2rem(151)};
            height: ${px2rem(50)};
          }
        }
      }

    }
  }

  @media ${device.laptop} {
    padding: ${px2rem(32)} ${px2rem(45)} 0 ${px2rem(45)};

    .download-wrapper {
      .download-app > .title-footer {
        max-width: ${px2rem(510)};
        font-size: ${px2rem(32)};
      }

      .download-app > .download-options {
        flex-direction: row;
        justify-content: start;
        width: 100%;
      }
    }
  }
`