import styled from 'styled-components';
import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';
import { device } from '@theme/size';

export const StyledLinks = styled.div`
  background-color: ${color.darkGrey};
  padding: ${px2rem(32)} ${px2rem(10)} ${px2rem(52)} ${px2rem(10)};
  width: 100%;
  display: flex;
  justify-content: start;

  .footerlinks-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .copyright-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      #buba-positive {
        width: ${px2rem(118)};
        height: ${px2rem(59)};
      }

      .copyright {
        color: ${color.white};
        font-family: 'Lexend', sans-serif;
        font-size: ${px2rem(12)};
      }
    }

    .wrapper-links {
      width: 100%;

      .links-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: ${px2rem(1)} solid ${color.white};
        padding-bottom: ${px2rem(24)};
        font-family: 'lexend Mega', sans-serif;
        font-size: ${px2rem(16)};

        li {
          margin-top: ${px2rem(24)};
        }

        li,
        li > * {
          color: ${color.white};
          cursor: pointer;
          letter-spacing: ${px2rem(-1.5)};
          width: max-content;
        }

        .link-commerce {
          /* margin-right: ${px2rem(190)}; */
          letter-spacing: ${px2rem(-1.5)};
          width: max-content;
        }

        .link-commerce > * {
          display: flex;
          align-items: center;
          color: ${color.lightPurple};
        }
      }

      .link-docs-container {
        display: flex;
        flex-direction: column;

        .link-doc {
          display: flex;
          align-items: center;
          font-family: 'lexend Mega', sans-serif;
          font-size: ${px2rem(16)};
          color: ${color.white};
          margin-top: ${px2rem(24)};
          letter-spacing: ${px2rem(-1.5)};
        }
      }
    }

    .networks {
      margin-top: ${px2rem(38)};
      .network {
        margin-right: ${px2rem(32)};
      }
    }
  }

  @media ${device.mobileL} {
    padding: ${px2rem(32)} ${px2rem(16)} ${px2rem(52)} ${px2rem(16)};
  }

  @media ${device.mobileM} {
    padding: ${px2rem(32)} ${px2rem(20)} ${px2rem(52)} ${px2rem(20)};
  }

  @media ${device.laptop} {
    padding: ${px2rem(49)} ${px2rem(20)} ${px2rem(47)} ${px2rem(20)};
    justify-content: center;

    .footerlinks-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: ${px2rem(1024)};
      width: 100%;

      .copyright-container {
        height: ${px2rem(98)};
        border-right: ${px2rem(1)} solid ${color.white};
        padding-right: ${px2rem(20)};
        max-width: ${px2rem(180)};
        width: 100%;
      }

      .wrapper-links {
        width: auto;

        .links-list {
          flex-direction: row;
          border: none;
          padding: 0;
          margin-bottom: ${px2rem(25)};

          li {
            margin: 0 ${px2rem(30)} 0 0;
          }
        }

        .link-docs-container {
          flex-direction: row;
          padding: 0;

          .link-doc {
            margin: 0 ${px2rem(34)} 0 0;
          }
        }
      }

      .networks {
        margin: 0;

        .network {
          margin-right: ${px2rem(14)};
        }
      }
    }
  }

  @media ${device.laptopL} {
    padding: ${px2rem(49)} 0 ${px2rem(47)} 0;

    .footerlinks-wrapper {
      max-width: ${px2rem(1440)};

      .copyright-container {
        padding: 0 ${px2rem(233)} 0 0;
        margin-left: ${px2rem(194)};
        max-width: ${px2rem(410)};
      }

      .wrapper-links {
        margin-right: 20px;
        .links-list {
          li {
            margin: 0 ${px2rem(48)} 0 0;
          }
        }
      }
    }
  }
`;
