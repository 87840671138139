import React from 'react';
import Menu from '@components/common/Menu';
// @ts-ignore
import { NavProps } from '@lib/types';
import { StyledNav } from './index.styled';
//@ts-ignore
import bubaBrand from '@assets/images/Positive.svg';
import { Link } from 'gatsby';


const Nav: React.FC<NavProps> = ({ activeSection }) => {
  return (
    <StyledNav>
      <div className="nav-wrapper">
        <Link to="/">
          <img id="brand" src={bubaBrand} alt="Logo" />
        </Link>
        <Menu activeSection={activeSection} />
      </div>
    </StyledNav>
  );
};

export default Nav;
