import { palette } from './palette';

export const color = {
  palette,
  red: palette.primary.red,
  black: palette.shades.black,
  darkGrey: palette.shades.darkGrey,
  white: palette.shades.white,
  lightPurple: palette.secondary.lightPurple,
  passionPink: palette.secondary.passionPink,
  leafGreen: palette.secondary.leafGreen,
  happyYellow: palette.secondary.happyYellow
}