import React from 'react';
import Button from '../Button';
import { StyledDownload } from './index.styled';
//@ts-ignore
import appStore from '@assets/images/appstore.png';
//@ts-ignore
import googlePlay from '@assets/images/googleplay.png';
//@ts-ignore
import iPhone from '@assets/images/iphone.png';
//@ts-ignore
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-circle-down.svg';
//@ts-ignore
import { ReactComponent as CommerceIcon } from '@assets/icons/comercio.svg';
import { color } from '@theme/color';

const Download: React.FC = () => {
  return (
    <StyledDownload>
      <div className="download-wrapper">
        <div className="download-app">
          <h3 className="title-footer">
            Somos la solución de empleo ideal para vos.
          </h3>
          <div className="download-options">
            {/* <Button
              isIntern={true}
              link="/empleados/registro/"
              icon={<ArrowDown fill="white" />}
              text="Descargar Buba"
              bgColor={color.red}
              wBtn={200}
            /> */}
            <a href="https://play.google.com/store/apps/details?id=com.bubajobs.app" target="_blank" rel="noopener noreferrer">
              <img id="badge-google" src={googlePlay} alt="Google Play" />
            </a>
            <a href="https://apps.apple.com/us/app/bubajobs/id1616932294" target="_blank" rel="noopener noreferrer">
              <img id="badge-app" src={appStore} alt="App Store" />
            </a>
            <Button
              isIntern={true}
              link="https://comercios.bubajobs.com.ar"
              icon={<CommerceIcon fill="white" />}
              text="Registrar mi comercio"
              bgColor={color.black}
              wBtn={260}
            />
          </div>
        </div>
        <img src={iPhone} alt="Phone View" />
      </div>
    </StyledDownload>
  );
};

export default Download;
