import styled from 'styled-components';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';
import { color } from '@theme/color';

export const StyledNav = styled.nav`
  display: flex;
  position: fixed;
  top: 0;
  justify-content: center;
  background-color: ${color.white};
  width: 100%;

  .nav-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${px2rem(10)};
    
    #brand {
      width: ${px2rem(96)};
    }
  }

  @media ${device.mobileM} {
    .nav-wrapper {
      padding: 0 ${px2rem(16)};
    }
  }

  @media ${device.tablet} {
    .nav-wrapper{
      height: ${px2rem(105)};
    }
  }

  @media ${device.laptop} {
    .nav-wrapper {
      padding: 0 ${px2rem(55)};
      
      #brand {
        margin-right: ${px2rem(24)};
      }
    }
  }

  @media ${device.laptopL} {
    .nav-wrapper {
      padding: 0 ${px2rem(65)};
      max-width: ${px2rem(1440)};
    }
  }
`;
