import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StyledLinks } from './index.styled';
//@ts-ignore
import brand from '@assets/images/Alternative.svg';
//@ts-ignore
import { ReactComponent as Doc } from '@assets/icons/file-text.svg';
//@ts-ignore
import { ReactComponent as Info } from '@assets/icons/info.svg';
//@ts-ignore
import { ReactComponent as Facebook } from '@assets/icons/fb-negative.svg';
//@ts-ignore
import { ReactComponent as Instagram } from '@assets/icons/ig-negative.svg';
//@ts-ignore
import { ReactComponent as CommerceIcon } from '@assets/icons/comercio.svg';
import { color } from '@theme/color';

const FooterLinks: React.FC = () => {
  const copyright = new Date();
  const year = copyright.getFullYear();

  return (
    <StyledLinks id="descargar">
      <div className="footerlinks-wrapper">
        <div className="copyright-container">
          <img id="buba-positive" src={brand} alt="Buba Logo" />
          <span className="copyright">&copy; {year} BubaJobs SAS</span>
        </div>
        <div className="wrapper-links">
          <ul className="links-list">
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <AnchorLink to="/#comercios">
              <li>Para comercios</li>
            </AnchorLink>
            <AnchorLink to="/#perfiles">
              <li>Perfiles</li>
            </AnchorLink>
            <AnchorLink to="/comercios/registro">
              <li>Contacto</li>
            </AnchorLink>
            {/* <AnchorLink to="/#testimonios">
              <li style={{"cursor": "default"}}>Testimonios</li>
            </AnchorLink> */}
            <li className="link-commerce">
              <AnchorLink to="https://comercios.bubajobs.com.ar">
                <CommerceIcon fill={color.lightPurple} />
                &nbsp;Mi comercio
              </AnchorLink>
            </li>
          </ul>
          <div className="link-docs-container">
            <Link to="/docs/terminos-condiciones">
              <a className="link-doc">
                <Doc fill="white" />
                &nbsp;Términos y condiciones
              </a>
            </Link>
            <Link to="/docs/politicas-privacidad">
              <a className="link-doc">
                <Info fill="white" />
                &nbsp;Políticas de Privacidad
              </a>
            </Link>
            <div className="networks">
              <a
                className="network"
                href="https://business.facebook.com/Bubajobs-109325953751611/?ref=py_c"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Facebook />
              </a>
              <a
                className="red"
                href="https://www.instagram.com/bubajobsapp/?hl=es-la"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledLinks>
  );
};

export default FooterLinks;
